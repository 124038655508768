import { createTheme } from '@mui/material/styles';

export default function getTheme(metadata) {
	return createTheme({
		palette: {
			primary: { main: metadata.color1, contrast: metadata.color2 },
			text: { primary: metadata.color1, secondary: metadata.color2 },
			action: {
				disabled: metadata.color1,
				disabledBackground: "#808080",
			}
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
				mobile: 0,
				tablet: 450,
				xtablet: 650,
				laptop: 900,
				desktop: 1200,
			},
		},
		components: {
			MuiButton: {
				defaultProps: {
					disableElevation: true,
				},
				styleOverrides: {
					root: {
						textTransform: 'none',
					}
				}
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						color: metadata.color1
					}
				}
			},
			MuiPickersDay: {
				styleOverrides: {
					root: {
						color: metadata.color2,
						backgroundColor: metadata.color1,
						"&:hover": {
							color: metadata.color1,
							backgroundColor: metadata.color2
						},
					},
					today: {
						color: metadata.color1
					},
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						borderWidth: '1px'
					}
				}
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundColor: metadata.color2
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: '12px',
						borderWidth: '4px'
					}
				}
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						fontSize: '20px',
						backgroundColor: metadata.color2,
						borderRadius: 10,
					}
				}
			},
			MuiAccordionSummary: {
				styleOverrides: {
					root: {
						padding: 0
					}
				}
			},
			MuiAccordionDetails: {
				styleOverrides: {
					root: {
						padding: 0
					}
				}
			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						paddingTop: 10,
						paddingBottom: 10,
						boxShadow: 'none',
						backgroundColor: 'inherit',
						'&:before': {
							opacity: '10%',
							backgroundColor: metadata.color1,
						}
					}
				}
			},
		},
		typography: {
			h2: {
				fontFamily: '"Montserrat", serif',
				color: metadata.color1,
				fontWeight: 700,
				fontSize: 45
			},
			h3: {
				fontFamily: '"Montserrat", serif',
				color: metadata.color1,
				fontWeight: 700,
				fontSize: 34
			},
			h4: {
				fontFamily: '"Montserrat", serif',
				fontSize: 22,
				color: metadata.color1
			},
			h6: {
				fontFamily: '"Montserrat", serif',
				fontSize: 16,
				color: metadata.color1
			},
			h5: {
				fontFamily: '"Montserrat", serif',
				fontSize: 22,
				color: metadata.color1
			},
			body1: {
				fontFamily: '"Montserrat", serif',
				fontSize: 16,
				color: metadata.color1
			},
			body2: {
				fontFamily: '"Montserrat", serif',
				fontSize: 16,
				color: metadata.color1,
				opacity: 0.5
			}
		},
	});
}
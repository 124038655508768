import React, {Fragment, useState, useEffect} from "react";
import {
  Button,
  IconButton,
  Theme,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Grid,
  Link,
  Box
} from "@mui/material";

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {hexToRgb} from 'helpers.js'
import { useTranslation } from "react-i18next";
// Components
import ContactInfo from "./ContactInfo";
import Modal from 'components/Modal.jsx';

const useStyles = makeStyles({
  box: {
    marginBottom: 15,
    borderRadius: '10px'
  }
});



export default function SharedAccessCode({components, handleUnlock}){
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [recoveryCode, setRecoveryCode] = useState(null)
  const [codeLoading, setCodeLoading] = useState(false)
  const [recoveryFailed, setRecoveryFailed] = useState(false)
  const [open, setOpen] = useState(false);
  let state = Object.values(components?.devices).find(d => !!d?.smart_lock_code)

  let color =  theme.palette.primary.main

  function requestRecoveryCode(device_id) {
    setCodeLoading(true)
    setRecoveryCode(device_id)
    handleUnlock(device_id, "recover").then(response => {
      setCodeLoading(false)
    })
  }


  let not_working_button = <Grid
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <Link 
        onClick={() => setOpen(true)}
        style={{pointerEvents: codeLoading ? 'none': ''}}
      >
        {t("code_not_working")}
      </Link>

    </Grid>

  let code = <Grid flex="1">
    <Typography variant='body2'>{t("access_code").toUpperCase()}</Typography>
    <Typography variant='h5'>{codeLoading ? (<CircularProgress size={20} style={{color: color}}/>) : state?.smart_lock_code}</Typography>
  </Grid>


  function closeModal(){
    setOpen(false)
    setRecoveryCode(null)
  }


  function getModalSpec(){
    if (codeLoading){
      return {
        title: `${t("loading")}...`,
        text: <CircularProgress size={20} style={{color: color}}/>
      }
    }
    if (recoveryCode != null){
      return {
        title: t("new_code"),
        text: <div>
          <Typography><strong> </strong>{t("lock_access_code_changed")}: </Typography>
          <Typography variant='h5'>{components.devices[recoveryCode]?.recovery_code}</Typography>
        </div>,
        buttons: [{'text': t("close"), onClick: () => closeModal()}]
      }
    }
    let modal_buttons = components.checkin.map((s, i) => (!!s.device_id && !components.devices[s.device_id]?.offline_lock)
        ? {text: components.devices[s.device_id]?.smart_lock_name, onClick: () => requestRecoveryCode(s.device_id)}
        : null
    )
    modal_buttons.push({text: t("cancel"), lowContrast: true, onClick: () => closeModal()})
    return {
      buttons: modal_buttons,
      title: t("code_not_working"),
      text: t("select_new_code")
    }
  }


  return <>
    <Modal
      {...getModalSpec()}
      open={open}
      setOpen={setOpen}
    />
    {!!state?.smart_lock_code && <Grid container sx={{mb: 2}}>
      <Grid flex="1">
        {code}
      </Grid>
      {not_working_button}
    </Grid>} 
  </>
  
}
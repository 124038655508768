import react, {useEffect, useState} from 'react';
import {
  Typography
} from "@mui/material";


export default function HTMLContent({v}){
	return <Typography 
	  className='html-content'
	  style={{wordWrap: 'break-word'}} 
	  dangerouslySetInnerHTML={{__html: v}}
	/>
}
import React from 'react'
import { makeStyles } from '@mui/styles'
import ScrollablePage from 'components/ScrollablePage'
import { Box, Typography } from '@mui/material'
import HTMLContent from 'components/HTMLContent'
import SectionHeaderCard from './Cards/SectionHeaderCard'
import defaultBGImg from 'assets/article_placeholder_image.webp'

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(5, 2.5, 5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    '& .ql-editor': { padding: 0 },
    '& div.ql-editor img': { maxWidth: '100%' },
  },
  simpleHeadercontainer: {
    width: '100%',
    maxWidth: 800,
    paddingTop: theme.spacing(7.5),
  },
}))


export default function GuidebookPage({ guidebookId, components, closePage }) {
  const classes = useStyles()
  let gb = components?.guidebooks?.find(g => g.id === guidebookId) ?? null

  const header = !!gb.header_image
    ? <SectionHeaderCard
      disableGrayscale
      title={gb?.name ?? ''}
      img={gb?.header_image ?? defaultBGImg}
    />
    : <div className={classes.simpleHeadercontainer}>
      <Box px={2.5}>
        <Typography variant='h3'>{gb.name}</Typography>
      </Box>
    </div>

  const guidebookContent = <div className={classes.content}>
    {!!gb && <HTMLContent v={gb.text} />}
  </div>


  return (<ScrollablePage
    disableActions
    onClose={closePage}
    header={header}
    pageContent={guidebookContent}
  />)
}
